import { animated } from 'react-spring'

import styles from './Heading.css'

export function HeadingXxxs({ title, h, layoutClassName = undefined }) {
  return <HeadingBase className={cx(styles.componentXxxs, layoutClassName)} {...{ h, title }} />
}

export function HeadingXxs({ title, h, layoutClassName = undefined }) {
  return <HeadingBase className={cx(styles.componentXxs, layoutClassName)} {...{ h, title }} />
}

export function HeadingXs({ title, h, layoutClassName = undefined }) {
  return <HeadingBase className={cx(styles.componentXs, layoutClassName)} {...{ h, title }} />
}

export function HeadingSm({ title, h, layoutClassName = undefined, animation = undefined }) {
  return <HeadingBase className={cx(styles.componentSm, layoutClassName)} {...{ h, title, animation }} />
}

export function HeadingMd({ title, h, layoutClassName = undefined, animation = undefined }) {
  return <HeadingBase className={cx(styles.componentMd, layoutClassName)} {...{ h, title, animation }} />
}

export function HeadingLg({ title, h, layoutClassName = undefined, animation = undefined }) {
  return <HeadingBase className={cx(styles.componentLg, layoutClassName)} {...{ h, title, animation }} />
}

export function HeadingXl({ title, h, layoutClassName = undefined }) {
  return <HeadingBase className={cx(styles.componentXl, layoutClassName)} {...{ h, title }} />
}

export function HeadingXxl({ title, h, layoutClassName = undefined, animation = undefined }) {
  return <HeadingBase className={cx(styles.componentXxl, layoutClassName)} {...{ h, title, animation }} />
}

function HeadingBase({ title, h, className, animation = undefined }) {
  const tag = !isNaN(h) ? `h${h}` : 'strong'
  const ElementBase = animation ? animated[tag] : tag

  if (isNaN(h) && process.env.NODE_ENV !== 'production') {
    console.debug(`Preferably use semantic heading order instead of 'strong'-tag.`)
  }

  return (
    <ElementBase className={cx(styles.componentBase, className)} style={animation}>
      {title}
    </ElementBase>
  )
}
