import { offset, useFloating, autoUpdate, size, useClick, useDismiss, useRole, useListNavigation, useTypeahead, useInteractions, flip, useFocus } from '@floating-ui/react'
import { useTranslate } from '/machinery/I18n'
import { Icon } from './Icon'
import { useEvent } from '/machinery/useEvent'

import chevronIcon from '/images/icons/chevron-down-boxed.raw.svg'
import checkIcon from '/images/icons/check.raw.svg'

import styles from './Dropdown.css'

export function DropdownForm({
  name,
  value,
  onChange,
  onFocus = undefined,
  onBlur = undefined,
  options = [],
  describedBy = undefined,
}) {
  return (
    <DropdownBase
      id={name}
      ButtonComponent={ButtonForm}
      className={styles.componentForm}
      {...{ value, options, describedBy, onChange, onFocus, onBlur }}
    />
  )
}

export function Dropdown({
  id,
  value,
  onChange,
  onFocus = undefined,
  onBlur = undefined,
  options = [],
  disabled = undefined,
  describedBy = undefined,
  valueSelector = undefined,
  hasSingleItem = undefined,
}) {
  return (
    <DropdownBase
      ButtonComponent={ButtonDefault}
      className={styles.component}
      {...{ id, value, options, describedBy, disabled, onChange, onFocus, onBlur, valueSelector, hasSingleItem }}
    />
  )
}

function DropdownBase({
  id,
  value,
  onChange,
  onFocus,
  onBlur,
  options = [],
  disabled = undefined,
  className = undefined,
  describedBy = undefined,
  valueSelector = undefined,
  hasSingleItem = undefined,
  ButtonComponent
}) {
  const { __ } = useTranslate()
  const {
    isOpen,
    itemProps,
    floatingProps,
    floatingStyles,
    referenceProps,
    selectedIndex,
    activeIndex
  } = useFloatingDropdown({
    value,
    options,
    disabled,
    onChange,
    valueSelector
  })

  const selectedItem = options[selectedIndex] ?? undefined

  return (
    <div className={cx(styles.componentBase, hasSingleItem && styles.hasSingleItem, className)} {...{ id }}>
      <ButtonComponent
        dataX='click-to-open-dropdown'
        aria-describedby={describedBy}
        layoutClassName={styles.selectedItemLayout}
        hasIcon={Boolean(selectedItem?.icon)}
        hasPlaceholder={!selectedItem?.label}
        {...{ disabled, onFocus, onBlur, hasSingleItem }}
        {...referenceProps}
      >
        {selectedItem?.icon && <Icon icon={selectedItem.icon} />}
        {selectedItem?.label || __`component-Dropdown-pick-option`}
        {!hasSingleItem && <Icon icon={chevronIcon} layoutClassName={styles.iconLayout} />}
      </ButtonComponent>

      {isOpen && (
        <Container style={{ ...floatingStyles, zIndex: 999 }} {...floatingProps}>
          {options.map(({ icon, label }, index) => (
            <Option
              key={index}
              hasIcon={Boolean(icon)}
              {...{ index, selectedIndex, activeIndex }}
              {...itemProps(index)}
            >
              {icon && <Icon {...{ icon }} />}
              {label}
              {index === activeIndex && (
                <Icon icon={checkIcon} layoutClassName={styles.iconLayout} />
              )}
            </Option>
          ))}
        </Container>
      )}
    </div>
  )
}

const Option = React.forwardRef(OptionImpl)
function OptionImpl({ children, hasIcon, index, selectedIndex, activeIndex, ...rest }, ref) {
  const isSelected = index === selectedIndex
  const isActive = index === activeIndex

  return (
    <div
      key={index}
      role="option"
      tabIndex={isActive ? 0 : -1}
      aria-selected={isSelected && isActive}
      className={cx(
        styles.componentOptionImpl,
        isActive && styles.isActive,
        hasIcon && styles.withAdditionalIcon
      )}
      {...{ ref }}
      {...rest}
    >
      {children}
    </div>
  )
}

const Container = React.forwardRef(ContainerImpl)
function ContainerImpl({ children, ...rest }, ref) {
  return (
    <div className={styles.componentContainerImpl} {...{ ref }} {...rest}>
      {children}
    </div>
  )
}


const ButtonDefault = React.forwardRef(ButtonDefaultImpl)
function ButtonDefaultImpl({
  children,
  dataX,
  disabled = false,
  hasIcon = false,
  hasPlaceholder = false,
  hasSingleItem = false,
  layoutClassName,
  ...rest
}, ref) {
  return (
    <ButtonBase
      className={cx(styles.componentButtonDefaultImpl, layoutClassName)}
      {...{ ref, children, hasIcon, disabled, hasPlaceholder, hasSingleItem }}
      {...rest}
    />
  )
}

const ButtonForm = React.forwardRef(ButtonFormImpl)
function ButtonFormImpl({
  children,
  dataX,
  disabled = false,
  hasIcon = false,
  hasPlaceholder = false,
  layoutClassName,
  ...rest
}, ref) {
  return (
    <ButtonBase
      className={cx(styles.componentButtonFormImpl, layoutClassName)}
      {...{ ref, children, hasIcon, hasPlaceholder }}
      {...rest}
    />
  )
}

const ButtonBase = React.forwardRef(ButtonBaseImpl)
function ButtonBaseImpl({
  children,
  disabled = false,
  dataX,
  hasIcon,
  hasPlaceholder,
  className,
  hasSingleItem = undefined,
  ...rest
}, ref) {
  return (
    <button
      type='button'
      data-x={dataX}
      aria-disabled={disabled ? 'true' : undefined}
      className={cx(
        styles.componentButtonBaseImpl,
        hasIcon && styles.withAdditionalIcon,
        hasPlaceholder && styles.hasPlaceholder,
        hasSingleItem && styles.hasSingleItem,
        className,
      )}
      {...{ ref, disabled, children }}
      {...rest}
    />
  )
}

function useFloatingDropdown({
  value,
  options,
  disabled,
  onChange,
  valueSelector
}) {
  const [isOpen, setIsOpen] = React.useState(false)
  const [activeIndex, setActiveIndex] = React.useState(null)
  const [selectedIndex, setSelectedIndex] = React.useState(getInitialIndex)

  const onIndexEvent = useEvent(getInitialIndex)

  React.useEffect(
    () => {
      // When changing available options,
      // we have to reset the selected list index.
      setSelectedIndex(onIndexEvent())
    },
    [options, onIndexEvent]
  )

  const { refs, floatingStyles, context } = useFloating({
    placement: 'bottom-start',
    open: isOpen,
    onOpenChange: setIsOpen,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(5),
      flip(),
      size({
        apply({ rects, elements, availableHeight }) {
          Object.assign(elements.floating.style, {
            maxHeight: `${availableHeight}px`,
            minWidth: `${rects.reference.width}px`
          })
        },
        padding: 10
      })
    ]
  })

  const listRef = React.useRef([])
  const listContentRef = React.useRef(options)
  const isTypingRef = React.useRef(false)

  const click = useClick(context, { event: 'mousedown' })
  const dismiss = useDismiss(context)
  const role = useRole(context, { role: 'listbox' })
  const focus = useFocus(context)
  const listNav = useListNavigation(context, {
    listRef,
    activeIndex,
    selectedIndex,
    onNavigate: setActiveIndex,
    loop: true
  })

  const typeahead = useTypeahead(context, {
    listRef: listContentRef,
    activeIndex,
    selectedIndex,
    onMatch: isOpen ? setActiveIndex : setSelectedIndex,
    onTypingChange(isTyping) {
      isTypingRef.current = isTyping
    }
  })

  const {
    getReferenceProps,
    getFloatingProps,
    getItemProps
  } = useInteractions([dismiss, role, focus, listNav, typeahead, click])

  return {
    isOpen,
    activeIndex,
    selectedIndex,
    floatingStyles,
    referenceProps: {
      ref: refs.setReference,
      ...getReferenceProps({
        disabled,
        'aria-disabled': disabled ? 'true' : undefined,
      })
    },
    floatingProps: {
      ref: refs.setFloating,
      ...getFloatingProps()
    },
    itemProps: i => ({
      ref: node => {
        listRef.current[i] = node
      },
      ...getItemProps({
        onClick() {
          handleSelect(i)
        },
        onKeyDown(event) {
          if (event.key === 'Enter') {
            event.preventDefault()
            handleSelect(i)
          }
        }
      })
    })
  }

  function handleSelect(x) {
    onChange(options[x].value)
    setSelectedIndex(x)
    setIsOpen(false)
  }

  function getInitialIndex() {
    return options.findIndex(compareSelectors) ?? null
  }

  function compareSelectors(x) {
    const a = x.value[valueSelector] ?? x.value
    const b = value[valueSelector] ?? value

    return a === b
  }
}
